import React from "react";
import classes from "./SideMenu.module.css";

const SideMenu: React.FC<any> = ({ isMobile, onMenuClick, active }) => {
  const icons = ["user-tie", "university", "cogs", "photo-video"];
  if (isMobile) icons.unshift("id-card-alt");

  return (
    <div className={isMobile ? classes.Mobile : classes.SideMenu}>
      {icons.map((value, index) => {
        return (
          <div
            key={value}
            onClick={() => onMenuClick(index)}
            style={{
              margin: "15px",
              height: "50px",
              textAlign: "center",
              fontSize: "1.7rem",
              color: `${active === index ? "#08fdd8" : "#4d4d4e"}`,
            }}
          >
            <i className={`fas fa-${value}`} />
          </div>
        );
      })}
    </div>
  );
};

export default SideMenu;