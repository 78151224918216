import React from 'react';
import classes from './Backdrop.module.css'

type PropTypes = {
    show?: boolean,
    onClick?: () => void,
}
const Backdrop: React.FC<PropTypes> = props => (
    props.show ? <div className={classes.Backdrop} onClick={props.onClick}></div> : null
)

export default Backdrop;