import React from "react";
import About from "./Abount/About";
import Education from "./Education/Education";
import Skills from "./Skills/Skills";
import Portfolio from "./Portfolio/Portfolio";
import Profile from "./Profile/Profile";
import classes from "./Content.module.css";

const Content: React.FC<any> = ({
  isMobile,
  translate,
  active,
  handleRefrenceOpen,
}) => {
  const pages = [
    <About />,
    <Education />,
    <Skills />,
    <Portfolio openRefrence={handleRefrenceOpen} />,
  ];
  if (isMobile) pages.unshift(<Profile />);
  return (
    <div
      className={classes.Content}
      id="content"
      style={{
        transition: "all 0.8s",
        color: "white",
        width: "100%",
        height: "100%",
        background: "rgb(24,24,24)",
        overflowY: "auto",
        overflowX: "hidden",
        display: isMobile ? "block" : "flex",
        justifyContent: "center",
        alignItems: "center",
        // marginBottom: "5rem",
        transform: `translateX(${translate})`,
      }}
    >
      {pages[active]}
    </div>
  );
};

export default Content;
