export const refrences = [
  {
    id: Math.random(),
    title: "TODO Hungary Mobile App",
    key: "mobile",
    date: "2018-12",
    description: `I've created a mobile app for TODOHungary.com, 
    the webapp was also made by me, we won a startup contest with it. The app shows the local events around on a map or in a list,
     you can filter it by category, and date. Also have usermanagemant and authentication.`,
    technology: ["Typescript", "HTML", "CSS", "Ionic", "Node"],
    images: [
      "https://firebasestorage.googleapis.com/v0/b/portfolio-45e48.appspot.com/o/portfolio%2Ftodo1.png?alt=media&token=1ca40b1b-5b23-4d96-bac6-34709d0bab5e",
      "https://firebasestorage.googleapis.com/v0/b/portfolio-45e48.appspot.com/o/portfolio%2Ftodomobile3.png?alt=media&token=02b5a8cf-e23e-4d52-b131-5ad9ef882e39",
    ],
  },
  {
    id: Math.random(),
    title: "TODOHungary.com",
    key: "web",
    date: "2018-8",
    description: `It was a webapp for tracking events around on a map. Also had admin menu, for managing the users, 
    and the events, and behing the scenes, there was a backend, what is fetching the events from the internet. 
    It is used an algorithm whitch made by me, you just had to give the city and the place and the it has fetched automatically, every day.`,
    technology: ["Typescript", "HTML", "CSS", "React", "Node"],
    images: [
      "https://firebasestorage.googleapis.com/v0/b/portfolio-45e48.appspot.com/o/portfolio%2Ftodoweb1.jpg?alt=media&token=049d60a1-7ee9-48d6-8416-a73434797163",
      "https://firebasestorage.googleapis.com/v0/b/portfolio-45e48.appspot.com/o/portfolio%2Ftodoweb2.png?alt=media&token=eeacb401-db63-4a1f-9b89-8422464e11d3",
    ],
  },
  {
    id: Math.random(),
    title: "Real estate finder",
    key: "web",
    date: "2019-4",
    description: `It is an interactive flat, real estate, with an indexed db behind, for the fast searching, have a list and a map view aswell.`,
    technology: ["Typescript", "HTML", "CSS", "React", "Node"],
    images: [
      "https://firebasestorage.googleapis.com/v0/b/portfolio-45e48.appspot.com/o/portfolio%2Fflat1.png?alt=media&token=7093603d-8838-4817-83ad-47783f577161",
      "https://firebasestorage.googleapis.com/v0/b/portfolio-45e48.appspot.com/o/portfolio%2Fflat2.png?alt=media&token=04595061-45fe-44a3-a2df-2dc7620af3a5",
    ],
  },
  {
    id: Math.random(),
    title: "JinnJuly",
    key: "web",
    date: "2020-4",
    description: `It's a 31 days drawing challenge`,
    technology: ["Typescript", "HTML", "CSS", "React", "Node"],
    images: [
      "https://firebasestorage.googleapis.com/v0/b/portfolio-45e48.appspot.com/o/images%2Fjinnjuly2.png?alt=media&token=09ca863c-7e74-4aa0-a898-b2dac64d6403",
      "https://firebasestorage.googleapis.com/v0/b/portfolio-45e48.appspot.com/o/images%2Fjinnjuly.png?alt=media&token=574b6c7e-7e67-4d7c-a9b8-e75ff199d47d",
    ],
  },
  {
    id: Math.random(),
    title: "Landing Page",
    key: "designs",
    date: "2020-5",
    description: `It a modern portfolio landing page design.`,
    technology: ["AdobeXD", "Photoshop"],
    images: [
      "https://firebasestorage.googleapis.com/v0/b/portfolio-45e48.appspot.com/o/images%2Flanding.png?alt=media&token=703392dc-b565-4594-a770-3348d6b36b64",
    ],
  },
  {
    id: Math.random(),
    title: "Chat app",
    key: "mobile",
    date: "2019-6",
    description: `It's a cross platform real time chat app, with firebase live db.`,
    technology: ["Typescript", "HTML", "CSS", "Ionic", "Node"],
    images: [
      "https://firebasestorage.googleapis.com/v0/b/portfolio-45e48.appspot.com/o/portfolio%2Fchat1.png?alt=media&token=29e5ec1c-0fe6-4244-9c7f-c0e0e834e8e8",
      "https://firebasestorage.googleapis.com/v0/b/portfolio-45e48.appspot.com/o/portfolio%2Fchat2.png?alt=media&token=3edadcc4-8c4e-409b-9d64-f8c3835216e6",
    ],
  },
  {
    id: Math.random(),
    title: "Norabau",
    key: "web",
    date: "2018-10",
    description: `Its a website for a furniture industry in Debrecen. Have admin menu too, manageable content, new refrences can be upload from admin. Also have authentication. And SEO friendly.`,
    technology: ["Typescript", "HTML", "CSS", "Angular", "Node"],
    images: [
      "https://firebasestorage.googleapis.com/v0/b/portfolio-45e48.appspot.com/o/portfolio%2Fnora3.png?alt=media&token=5fd361f6-59be-47a4-af94-ecc88d2f3d66",
      "https://firebasestorage.googleapis.com/v0/b/portfolio-45e48.appspot.com/o/portfolio%2Fnora2.png?alt=media&token=14cbc8b4-f7f4-4e70-a5c4-92f9e59f8fee",
    ],
  },
  {
    id: Math.random(),
    title: "Chat app design",
    key: "designs",
    date: "2018-10",
    description: `I've created the full design of the chat app, with wireframe and clickabble content.`,
    technology: ["PS", "AdobeXD"],
    images: [
      "https://firebasestorage.googleapis.com/v0/b/portfolio-45e48.appspot.com/o/portfolio%2Fchat_design_2.png?alt=media&token=2fa855f2-a2fa-4fbe-9bb9-a0702264cf2b",
      "https://firebasestorage.googleapis.com/v0/b/portfolio-45e48.appspot.com/o/portfolio%2Fchat_design_1.png?alt=media&token=4e15289a-99ce-4d06-b364-403754203d09",
    ],
  },
];

export const school = [
  {
    class: "Programming",
    school: "Ruander Programming School",
    date: "2015-2016",
    address: "Budapest, HU",
    description: `I realy enjoyed the programming on the University but 
    i want to dive mutch more into, for example, create Mobile and Web Apps, 
    so i chose a programming school too, and the time had come i met with JavaScript...`,
  },
  {
    class: "Electric Engineering",
    school: "University of Budapest",
    date: "2014-2017",
    address: "Budapest, HU",
    description: `I felt that i wanted to learn more about IT and Electricity
    so after the Mechatronic i went to another University to be an Electric Engineer too. 
    Finaly Object Oriented Programming C++ and JAVA, and mutch more advance controllers like 
    Raspberry PI, Arduino. `,
  },
  {
    class: "Mechatronic Engineering",
    school: "University of Debrecen",
    date: "2012-2014",
    address: "Debrecen, HU",
    description: `Then i finaly arrived to the University, 
    the mechatronic is consist of Electric, Mechanic and IT, it has sounds 
    nice to me for the first time, but then i figured out, 
    the Electric and IT parts fits me, better then the Mechanic. I met with a "higher level" 
    programming language its called C , for controling PIC microcontrollers.`,
  },
  {
    class: "Electric technical",
    school: "Beregszaszi Pal Technical School",
    date: "2010-2012",
    address: "Debrecen, HU",
    description: `After my graduation, i decided to stay in my high school to 
    get a technical certificate. I learned here about electrotechnics and electric controllers ,
    microcontrolers, and some controller programming in Assembly.`,
  },
  {
    class: "Graduation",
    school: "Beregszaszi Pal High School",
    date: "2006-2010",
    address: "Debrecen, HU",
    description: `There was the first place where i met with programming, but only with 
    the basics of BASIC (Haha). I created my first website here, it was a static HTML file in notepad. 
    My two majon subject was IT and Math.`,
  },
];

export const experiences = [
  {
    position: "Freelancer",
    employer: "Self-employed",
    date: "2017-",
    address: "Debrecen, HU",
    description: `I did some part time freelance jobs next to my primary job till 2017, these are mainly webapps,
    webshop configs, mobile apps or designs. Or sometimes just consulting, advertising. I got more request 
    so in 2019 i decided to be a full time Freelancer.`,
    responsibilities: [
      "Design",
      "WebApp",
      "MobileApp",
      "Hardware",
      "Consulting",
    ],
    technologies: [
      "TypeScript",
      "React",
      "Angular",
      "Ionic",
      "ReactNative",
      "HTML",
      "CSS",
      "Node",
      "Firebase",
      "AWS",
      "Arduino",
      "RaspberryPI",
    ],
  },
  {
    position: "Tech Lead",
    employer: "RapidSearch",
    date: "2018-2019",
    address: "Debrecen, HU",
    description: `I came here as a full stack JS dev, and tech lead. The backend was node, and the frontend is ReactJS. 
    We worked an eCommerce app. RapidSearch app is a site search engine for webshops. Behind the backend there is a 
    indexed DB so it can be very fast. Working here was super exciting, learned some new technologies like Elastic Cloud,
    and some DevOps responsibilities. We worked in scrum and the official language was english only.`,
    responsibilities: [
      "Team Lead",
      "Introduce New Technologies",
      "Coding",
      "Daily Standups",
      "Code Review",
      "Merge",
      "Deploy",
    ],
    technologies: [
      "TypeScript",
      "React",
      "HTML",
      "CSS",
      "Node",
      "Firebase",
      "Elastic Cloud",
    ],
  },
  {
    position: "Full Stack Developer",
    employer: "Ulysys",
    date: "2016-2018",
    address: "Budapest, HU",
    description: `I had came here as a Full Stack Java developer, but for the frontend, we used a lots of vanilla JS 
    and jQuery, and the backend was java. I learned a lot here, 
    for my last year i had become a merge master, i had to merge to code to production, code review etc. I learned a lot 
    about databases too, mostly SQL but some NoSQL too. Then i got this feeling, "I want more. I realy like javascript i want 
    to be a JS dev, learn some frontend framework, with node backend, and creating awesome webApps". So i had to move...`,
    responsibilities: [
      "Creating frontend ",
      "Write backend",
      "Merge",
      "Code Review",
    ],
    technologies: [
      "Java",
      "JavaScript",
      "jQuery",
      "HTML",
      "CSS",
      "Git",
      "Jira",
      "SQL",
    ],
  },
  {
    position: "IT support",
    employer: "Self-employed",
    date: "2012-2016",
    address: "Debrecen, HU",
    description: `My family and friends thought if i learn programming then "I can fix theirs PC" well basically 
    that was true, so i figured out its a good part job next to the university.`,
    responsibilities: [
      "OS install",
      "Software install",
      "Web-development",
      "Hardware upgrade",
    ],
    technologies: ["HTML", "CSS", "Linux"],
  },
  {
    position: "Hardware Programmer",
    employer: "Teva Hungary El-Tech",
    date: "20010-2012",
    address: "Debrecen, HU",
    description: `My task was to planning and creating circuits, and programming the controllers for the
     automated production lines, then create a documentation.`,
    responsibilities: [
      "Controller programming",
      "Automatization",
      "Printed circuits",
      "Documentation",
    ],
    technologies: ["Assembly"],
  },
];
export const technicalSkills = [
  { title: "Javascript", precent: 98 },
  { title: "React", precent: 95 },
  { title: "ReactNative", precent: 88 },
  { title: "Angular 2+", precent: 80 },
  // { title: "HTML / CSS", precent: 97 },
  // { title: "UX/UI Design", precent: 95 },
  // { title: "Web Development", precent: 98 },
  // { title: "Mobile Development", precent: 90 },
  // { title: "Hardware", precent: 85 },
];
export const entries = [
  {
    label: "Git",
  },
  {
    label: "Leaflet",
  },
  {
    label: "StackOverFlow",
  },
  {
    label: "HTML",
  },
  {
    label: "jQuery",
  },
  {
    label: "NodeJS",
  },
  {
    label: "JSFiddle",
  },
  {
    label: "CodePen",
  },
  {
    label: "three.js",
  },
  {
    label: "Java",
  },
  {
    label: "Arduino",
  },
  {
    label: "RaspberryPI",
  },
  {
    label: "Typescript",
  },
  {
    label: "React",
  },
  {
    label: "Angular 2+",
  },
  {
    label: "ReactNative",
  },
  {
    label: "Ionic",
  },
  {
    label: "CSS",
  },
  {
    label: "Spring",
  },
  {
    label: "GraphQL",
  },
  {
    label: "NoSQL",
  },
  {
    label: "MongoDB",
  },
  {
    label: "Firebase",
  },
  {
    label: "ElastiCloud",
  },
  {
    label: "AWS",
  },
  {
    label: "yarn",
  },
  {
    label: "Bootstrap",
  },
  {
    label: "Javascript",
  },
  {
    label: "Storybook",
  },
  {
    label: "Redux",
  },
  {
    label: "RxJS",
  },
];
