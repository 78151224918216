import React from "react";
import classes from "./About.module.css";
import Card from "../../UI/Card/Card";
import office from "../../../assets/img/office.jpg";
import aboutMe from "../../../assets/img/about_me.jpg";
import snowboard from "../../../assets/img/about_snowboard.jpg";
import dog from "../../../assets/img/about_dog.jpg";
import printer from "../../../assets/img/about_printer.jpg";
import factMain from "../../../assets/img/fact_main.jpg";
import factTodo from "../../../assets/img/fact_todo.jpg";
import factForrasztas from "../../../assets/img/fact_forrasztas.jpg";
import factRaspberry from "../../../assets/img/fact_raspberry.jpg";
import factWork from "../../../assets/img/fact_work.jpg";
import factTable from "../../../assets/img/fact_table.jpg";
import video1 from "../../../assets/img/video1.jpg";
import video2 from "../../../assets/img/video2.jpg";
import video3 from "../../../assets/img/video3.jpg";

const About: React.FC<any> = (props) => {
  const age = new Date().getFullYear() - 1992;
  return (
    <div className={classes.About}>
      <Card
        icon="far fa-address-card"
        title="About"
        classes={[classes.AboutContainer]}
      >
        <p>
          “Im a {age} years old developer, who born in Debrecen, Hungary.
          Originally i learned to be an engineer, but i always liked
          programming, i was 14yo when i write my first line of code, so i've
          become a dev. Right now im a freelancer and building my own bussiness.
          Im working from home, i've created my own office at home. I like the
          electronics and hardwares too, for example connect a raspberryPI or
          arduino to a mobile app, and create some own smart home features its
          super fun, it's one of my hobbies. I have a 3D printer too. Im
          learning 3D modeling. In my free time i like reading, learn new
          technologies, playing, or going out, working on my own interactive
          boardgame. On winter i usually go snowboarding. I have a beautful
          girlfriend and two sausage-dog.“
        </p>
        <div className={classes.ImageContainer}>
          {/* <div
            className={classes.ImgLeft}
            style={{
              width: "58%",
              background: `url(${office})`,
            }}
          /> */}
          <img
            alt=""
            className={classes.ImgLeft}
            src={office}
            style={{ width: "58%", objectFit: "cover" }}
          />
          <div className={classes.ImgRightContainer}>
            {/* <div
              className={classes.ImgRight}
              style={{
                // background: `url(https://firebasestorage.googleapis.com/v0/b/portfolio-45e48.appspot.com/o/images%2Fabout_me.jpg?alt=media&token=5f2774fc-3ece-42d1-b796-f416e0d31acb)`,
                background: `url(${aboutMe})`,
              }}
            /> */}
            <img
              alt=""
              className={classes.ImgRight}
              src={aboutMe}
              style={{ objectFit: "cover" }}
            />
            {/* <div
              className={classes.ImgRight}
              style={{
                // background: `url(https://firebasestorage.googleapis.com/v0/b/portfolio-45e48.appspot.com/o/images%2Fabout_snowboard.jpg?alt=media&token=8e15439d-37c0-4c65-9c25-53b60112cfdc)`,
                background: `url(${snowboard})`,
              }}
            /> */}
            <img
              alt=""
              className={classes.ImgRight}
              src={snowboard}
              style={{ objectFit: "cover" }}
            />
          </div>
          <div className={classes.ImgRightContainer}>
            {/* <div
              className={classes.ImgRight}
              style={{
                // background: `url(https://firebasestorage.googleapis.com/v0/b/portfolio-45e48.appspot.com/o/images%2Fabout_printer.jpg?alt=media&token=9f06b637-b447-422c-9028-3f71a5945c33)`,
                background: `url(${printer})`,
              }}
            /> */}
            <img
              alt=""
              className={classes.ImgRight}
              src={printer}
              style={{ objectFit: "cover" }}
            />
            {/* <div
              className={classes.ImgRight}
              style={{
                // background: `url(https://firebasestorage.googleapis.com/v0/b/portfolio-45e48.appspot.com/o/images%2Fabout_dog.jpg?alt=media&token=847df323-0109-4021-b818-245bd3d74e9d)`,
                background: `url(${dog})`,
              }}
            /> */}
            <img
              alt=""
              className={classes.ImgRight}
              style={{ objectFit: "cover" }}
              src={dog}
            />
          </div>
        </div>
      </Card>
      <hr />
      <Card
        icon="fas fa-puzzle-piece"
        classes={[classes.FactsContainer]}
        title="Facts"
      >
        <p>
          “I've been working in programming more then 10 years now, in the past
          5 years mostly Web and Mobile. I worked on many different project
          eCommerce, Enterprise, Goverment, SmartHome. I have many own project
          too, hobbie project, tutorial projects. With one of them we win a
          startup contest. It's called TODOHungary, it was an interactive map to
          find nearby events, filter it by date and category, also created a
          mobile app for it. You can check my works and projects on the
          refrences page. I've created some npm package, UI designs, gave some
          stackoverflow answers.”
        </p>
        <div className={classes.Facts}>
          <div className={classes.FactContainer}>
            <div className={classes.Fact}>
              <i className="fas fa-code-branch" />
              35
            </div>
            <div>Projects</div>
          </div>
          <div className={classes.FactContainer}>
            <div className={classes.Fact}>
              <i className="fab fa-github" />
              48
            </div>
            <div>Repositories</div>
          </div>
          <div className={classes.FactContainer}>
            <div className={classes.Fact}>
              <i className="fab fa-node" />3
            </div>
            <div>Package</div>
          </div>
          <div className={classes.FactContainer}>
            <div className={classes.Fact}>
              <i className="fas fa-cubes"></i>
              19
            </div>
            <div>3D print</div>
          </div>
          <div className={classes.FactContainer}>
            <div className={classes.Fact}>
              <i className="fab fa-youtube" />
              15
            </div>
            <div>Video</div>
          </div>
          <div className={classes.FactContainer}>
            <div className={classes.Fact}>
              <i className="fab fa-stack-overflow" />
              25
            </div>
            <div>Answers</div>
          </div>
        </div>
        <div className={classes.ImageContainer}>
          {/* <div
            className={`${classes.ImgLeft} ${classes.FactsMain}`}
            style={{
              // background: `url(https://firebasestorage.googleapis.com/v0/b/portfolio-45e48.appspot.com/o/images%2Ffact_main.jpg?alt=media&token=029693c5-3933-4230-a295-cbae2fac8d85)`,
              background: `url(${factMain})`,
              width: "38%",
            }}
          /> */}
          <img
            alt=""
            className={`${classes.ImgLeft} ${classes.FactsMain}`}
            src={factMain}
            style={{
              width: "38%",
              objectFit: "cover",
            }}
          />
          {/* <div
            className={`${classes.ImgLeft} ${classes.FactsSecondary}`}
            style={{
              // background: `url(https://firebasestorage.googleapis.com/v0/b/portfolio-45e48.appspot.com/o/images%2Ffact_todo.jpg?alt=media&token=74ee02c3-1160-4050-ab42-42aaeaa0b19e)`,
              background: `url(${factTodo})`,
              width: "20%",
            }}
          /> */}
          <img
            alt=""
            className={`${classes.ImgLeft} ${classes.FactsSecondary}`}
            src={factTodo}
            style={{
              width: "20%",
              objectFit: "cover",
            }}
          />
          <div className={classes.ImgRightContainer}>
            {/* <div
              className={classes.ImgRight}
              style={{
                // background: `url(https://firebasestorage.googleapis.com/v0/b/portfolio-45e48.appspot.com/o/images%2Ffact_work.jpg?alt=media&token=9e9402d9-d262-4243-ba81-ea231d0eace5)`,
                background: `url(${factWork})`,
              }}
            /> */}
            <img
              alt=""
              style={{ objectFit: "cover" }}
              className={classes.ImgRight}
              src={factWork}
            />
            {/* <div
              className={classes.ImgRight}
              style={{
                // background: `url(https://firebasestorage.googleapis.com/v0/b/portfolio-45e48.appspot.com/o/images%2Ffact_forrasztas.jpg?alt=media&token=b4888640-851e-4348-89d6-bbe0cb898006)`,
                background: `url(${factForrasztas})`,
              }}
            /> */}
            <img
              alt=""
              style={{ objectFit: "cover" }}
              className={classes.ImgRight}
              src={factForrasztas}
            />
          </div>
          <div className={classes.ImgRightContainer}>
            {/* <div
              className={classes.ImgRight}
              style={{
                // background: `url(https://firebasestorage.googleapis.com/v0/b/portfolio-45e48.appspot.com/o/images%2Ffact_raspberry.jpg?alt=media&token=76f2d289-a81c-4f2a-bdde-70557b60bd5d)`,
                background: `url(${factRaspberry})`,
              }}
            /> */}
            <img
              alt=""
              style={{ objectFit: "cover" }}
              className={classes.ImgRight}
              src={factRaspberry}
            />
            {/* <div
              className={classes.ImgRight}
              style={{
                // background: `url(https://firebasestorage.googleapis.com/v0/b/portfolio-45e48.appspot.com/o/images%2Ffact_table.jpg?alt=media&token=405018c0-adfa-4bdc-a955-0f6546d4b2e1)`,
                background: `url(${factTable})`,
              }}
            /> */}
            <img
              alt=""
              style={{ objectFit: "cover" }}
              className={classes.ImgRight}
              src={factTable}
            />
          </div>
        </div>
      </Card>
      <hr />
      <Card title="Video" icon="fas fa-video">
        <p>
          “I have learnt a lot from books, videos, and online courses, so i've
          decided to start my own tech chanel, video tutorials, to give back
          something. Tips and tricks, best practises, technology guides,
          comparisons and right now i'm also working on a full Udemy Crash
          Course.”
        </p>
        <div className={classes.ImageContainer}>
          {/* <div
            className={classes.ImgLeft}
            style={{
              // background: `url(https://firebasestorage.googleapis.com/v0/b/portfolio-45e48.appspot.com/o/images%2Fvideo1.jpg?alt=media&token=8833e6f3-2661-4e25-a916-91884f437d08)`,
              background: `url(${video1})`,
              width: "58%",
            }}
          /> */}
          <img
            alt=""
            className={classes.ImgLeft}
            src={video1}
            style={{
              width: "58%",
              objectFit: "cover",
            }}
          />
          <div
            className={classes.ImgRightContainer}
            style={{
              width: "40%",
            }}
          >
            {/* <div
              className={classes.ImgRight}
              style={{
                // background: `url(https://firebasestorage.googleapis.com/v0/b/portfolio-45e48.appspot.com/o/images%2Fvideo2.jpg?alt=media&token=fe4360c7-6f5e-4a94-8d0c-868bbc8adcbf)`,
                background: `url(${video2})`,
              }}
            /> */}
            <img
              alt=""
              className={classes.ImgRight}
              src={video2}
              style={{
                objectFit: "cover",
              }}
            />
            {/* <div
              className={classes.ImgRight}
              style={{
                // background: `url(https://firebasestorage.googleapis.com/v0/b/portfolio-45e48.appspot.com/o/images%2Fvideo3.jpg?alt=media&token=e8d20dad-cbd4-4659-8283-e21cd4f244b3)`,
                background: `url(${video3})`,
              }}
            /> */}
            <img
              alt=""
              className={classes.ImgRight}
              src={video3}
              style={{
                objectFit: "cover",
              }}
            />
          </div>
        </div>
        <div className={classes.Youtube}>
          <iframe
            width="420"
            height="345"
            src="https://www.youtube.com/embed/h-dT5Du4-yw?controls=1"
          ></iframe>
        </div>
      </Card>
      <hr />
      <Card
        title="Pricing"
        icon="fas fa-piggy-bank"
        classes={[classes.Priceheading]}
      >
        <div className={classes.PriceContainer}>
          <div className={classes.Price}>
            <ul>
              <li className={classes.Title}>Full-time</li>
              <li className={classes.PriceHour}>
                <span>30</span>
              </li>
              <li>consulting</li>
              <li>web development</li>
              <li>mobile development</li>
              <li>design</li>
              <div className={classes.Choose}>Choose</div>
            </ul>
          </div>
          <div className={classes.Price}>
            <ul>
              <li className={classes.Title}>Fixed Priced project</li>
              <li className={classes.PriceHour}>
                <span>39</span>
              </li>
              <li>consulting</li>
              <li>web development</li>
              <li>mobile development</li>
              <li>design</li>
              <div className={classes.Choose}>Choose</div>
            </ul>
          </div>
          <div className={classes.Price}>
            <ul>
              <li className={classes.Title}>Hourly work</li>
              <li className={classes.PriceHour}>
                <span>99</span>
              </li>
              <li>consulting</li>
              <li>web development</li>
              <li>mobile development</li>
              <li>design</li>
              <div className={classes.Choose}>Choose</div>
            </ul>
          </div>
          <span
            style={{
              position: "relative",
              bottom: "-2rem",
              fontWeight: "lighter",
              fontSize: "80%",
              width: "100%",
              textAlign: "center",
            }}
          >
            Prices are for information only.
          </span>
        </div>
      </Card>
    </div>
  );
};

export default About;
