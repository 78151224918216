import React, { useRef, useState, useEffect } from "react";
import "./profile.css";
// import * as THREE from "three";
import Plane from "../../plane/Plane";

const Profile: React.FC<any> = (props) => {
  const ref = useRef<any>(null);
  const [firstName, setFirstName] = useState<any>("");
  const [lastName, setLastName] = useState<any>("");
  const [subject, setSubject] = useState<any>("");
  const [email, setEmail] = useState<any>("");
  const [message, setMessage] = useState<any>("");
  const [sent, setSent] = useState(false);

  const handleMailSend = () => {
    if (!sent) {
      const mail: any = {};
      mail.subject = subject;
      mail.template = `
    <div style="color:black;">
      <div>
        <h3 style="margin-bottom: 0; display: inline;">Name:</h3> ${lastName} ${firstName}
      </div>
      <div>
        <h3 style="margin-bottom: 0; display: inline;">Email:</h3> ${email}
      </div>
      <h3 style="margin-bottom: 0;">Message:</h3>
      <div style="min-height: 10rem; max-height: 10rem; min-width: 24rem; max-width: 24rem; padding: 12px;">
      ${message}
      </div>
    </div>`;
      console.log("send");
      fetch("https://daviddebreceni.com/sendmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(mail),
      })
        .then((res) => setSent(true))
        .catch();
    }
  };

  return (
    <div
      className="scene scene--card"
      style={
        {
          // height:`${window.innerHeight}px`
        }
      }
    >
      <div ref={ref} className="card">
        <div className="card__face card__face--front">
          <div className="picture"></div>
          <div className="contact_wrapper">
            <div className="name">David Debreceni</div>
            <div className="title">Developer / Freelancer / Designer</div>
            <div className="contact">
              <ul>
                <li>
                  <i className="far fa-envelope">
                    {" "}
                    <a href="mailto:debrecenidavid0311@gmail.com">
                      debrecenidavid0311@gmail.com
                    </a>
                  </i>
                </li>
                <li>
                  <i className="fas fa-phone-alt">
                    {" "}
                    <a href="tel:+36307503559">+36 30 750 3559</a>
                  </i>
                </li>
                <li>
                  <i className="fas fa-map-marker-alt">
                    {" "}
                    <a href="http://www.google.hu/maps/place/Debrecen/@47.531009,21.590143">
                      Debrecen, Hungary
                    </a>
                  </i>
                </li>
                <li>
                  <i className="fas fa-file-download">
                    {" "}
                    <a
                      style={{ color: "#08fdd8", opacity: 0.4 }}
                      href="https://firebasestorage.googleapis.com/v0/b/portfolio-45e48.appspot.com/o/CV.pdf?alt=media&token=d00daee3-df38-46bd-966d-b0da8c533ebc"
                      download
                    >
                      Download CV!
                    </a>
                  </i>
                </li>
              </ul>
            </div>
          </div>
          <p className="bio">
            “ Hi my name is David. I'm a freelance developer. If you need a
            mobile, hardware, web app, or desing, or consulting, feel free to
            contact me below.“ 
          </p>
          <div
            className="flip"
            onClick={() => {
              ref.current.classList.toggle("is-flipped");
            }}
          >
            Contact Me!
          </div>
          <div className="social">
            <ul>
              <li>
                <a href="https://www.linkedin.com/in/david-debreceni-140070145/">
                  <i className="fab fa-linkedin" />
                </a>
              </li>
              <li>
                <a href="https://twitter.com/david_debreceni">
                  <i className="fab fa-twitter" />
                </a>
              </li>
              <li>
                <a href="https://www.youtube.com/channel/UC3aeT8VcWcAxyVgIuHzDnTA">
                  <i className="fab fa-youtube" />
                </a>
              </li>
              <li>
                <a href="https://github.com/debrecenid">
                  <i className="fab fa-github" />
                </a>
              </li>
              <li>
                <a href="https://gitlab.com/debrecenidavid">
                  <i className="fab fa-gitlab" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="card__face back card__face--back">
          <h2 style={{ marginBottom: "0.8rem", marginTop: "1rem" }}>Contact</h2>
          {/* <div id="animated_earth"></div> */}
          <Plane />
          <p style={{ textAlign: "left" }}>
            Hello, <br />
            Im waiting for your message.In the subject try to be specific to
            witch service do you needed e.g. Mobile App, Web App and then write
            a couple lines from it. Im trying to write back as soon as possible.
            <br />
            Thank you! David
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <input
              style={{ width: "45%" }}
              type="text"
              placeholder="First Name"
              value={firstName}
              onChange={(ev) => setFirstName(ev.target.value)}
            />
            <input
              style={{ width: "45%" }}
              type="text"
              placeholder="Last Name"
              value={lastName}
              onChange={(ev) => setLastName(ev.target.value)}
            />
          </div>
          <input
            type="text"
            placeholder="Email"
            value={email}
            onChange={(ev) => setEmail(ev.target.value)}
          />
          <input
            type="text"
            placeholder="Subject"
            value={subject}
            onChange={(ev) => setSubject(ev.target.value)}
          />
          <textarea
            placeholder="Message"
            value={message}
            onChange={(ev) => setMessage(ev.target.value)}
          />
          <div
            style={{ display: "flex", justifyContent: "space-evenly" }}
            className="arrowContainer"
          >
            <div>
              <i
                style={{ fontSize: "1.5rem" }}
                onClick={() => {
                  ref.current.classList.toggle("is-flipped");
                }}
                className="fas fa-arrow-left"
              />
            </div>
            <div
              onClick={handleMailSend}
              style={{
                cursor: sent ? "not-allowed" : "pointer",
              }}
            >
              <i
                style={{ fontSize: "1.5rem" }}
                className="far fa-paper-plane"
              />
            </div>
          </div>
          {sent ? (
            <div
              style={{
                bottom: "10px",
                width: "100%",
                fontSize: "15px",
              }}
            >
              {"Sent. Thank you."}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Profile;
