import React, { useEffect } from "react";

const Words: React.FC<any> = (props) => {
  const settings = {
    entries: props.entries,
    width: "100%",
    height: "100%",
    radius: "65%",
    radiusMin: 75,
    bgDraw: true,
    bgColor: "transparent",
    opacityOver: 1.0,
    opacityOut: 0.05,
    opacitySpeed: 6,
    fov: 800,
    speed: 0.8,
    fontFamily: "Oswald, Arial, sans-serif",
    fontSize: "15",
    fontColor: "#fff",
    fontWeight: "normal", //bold
    fontStyle: "normal", //italic
    fontStretch: "normal", //wider, narrower, ultra-condensed, extra-condensed, condensed, semi-condensed, semi-expanded, expanded, extra-expanded, ultra-expanded
    fontToUpperCase: false,
    tooltipFontFamily: "Oswald, Arial, sans-serif",
    tooltipFontSize: "11",
    tooltipFontColor: "#fff",
    tooltipFontWeight: "normal", //bold
    tooltipFontStyle: "normal", //italic
    tooltipFontStretch: "normal", //wider, narrower, ultra-condensed, extra-condensed, condensed, semi-condensed, semi-expanded, expanded, extra-expanded, ultra-expanded
    tooltipFontToUpperCase: false,
    tooltipTextAnchor: "left",
    tooltipDiffX: 0,
    tooltipDiffY: 10,
    animatingSpeed: 0.01,
    animatingRadiusLimit: 1.3,
  };
  useEffect(() => {
    setTimeout(() => {
      $("#animation").svg3DTagCloud(settings);
    }, 0);
  }, []);
  return <div id="animation" style={{ cursor: "pointer" }}></div>;
};

export default Words;
