import React from "react";
import classes from "./Education.module.css";
import Card from "../../UI/Card/Card";
import { school, experiences } from "../../../dummmy";

const Education: React.FC<any> = (props) => {
  const eduContent = school.map((school) => {
    return (
      <Card key={school.class} style={{ marginBottom: "1.5rem" }}>
        <ul>
          <li>
            <h4>
              {school.class} <span>at {school.school}</span>
            </h4>
            <h5>{school.date}</h5>
            <div className={classes.Title}>{school.address}</div>
            <p>{school.description}</p>
          </li>
        </ul>
      </Card>
    );
  });
  const expContent = experiences.map((exp) => {
    return (
      <Card
        key={`${exp.position} ${exp.employer}`}
        style={{ marginBottom: "1.5rem" }}
      >
        <ul>
          <li>
            <h4>
              {exp.position} <span>at {exp.employer}</span>
            </h4>
            <h5>{exp.date}</h5>
            <div className={classes.Title}>{exp.address}</div>
            <p>{exp.description}</p>
            <div className={classes.ListWrapper}>
              <div className={classes.RespTech}>
                <p>Responsibility</p>
                <ul>
                  {exp.responsibilities.map((res) => (
                    <li key={exp.date + res}>{res}</li>
                  ))}
                </ul>
              </div>
              <div className={classes.RespTech}>
                <p>Technology</p>
                <ul>
                  {exp.technologies?.map((tech) => (
                    <li key={exp.date + tech}>{tech}</li>
                  ))}
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </Card>
    );
  });
  return (
    <div className={classes.Education}>
      <div className={classes.Wrapper}>
        <div className={classes.Edu}>
          <i className="fas fa-user-graduate" />
          <h2>Education</h2>
          {eduContent}
        </div>
        <div className={classes.Exp}>
          <i className="fas fa-briefcase" />
          <h2>Work Experience</h2>
          {expContent}
        </div>
      </div>
    </div>
  );
};

export default Education;
