import React from "react";
import classes from "./Bar.module.css";

type PropTypes = {
  bars: {
    title: string;
    precent: number;
  }[];
};

const Bar: React.FC<PropTypes> = (props) => {
  return (
    <div className={classes.Bar}>
      {props.bars.map((bar) => {
        return (
          <div key={`${bar.title}${bar.precent}`}>
            <div className={classes.TitleWrapper}>
              <div className={classes.Title}>{bar.title}</div>
              <div className={classes.Precent}>{bar.precent}%</div>
            </div>
            <div className={classes.Status}></div>
            <div
              className={classes.Progress}
              style={{ width: `${bar.precent}%` }}
            ></div>
          </div>
        );
      })}
    </div>
  );
};

export default Bar;
