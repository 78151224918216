import React from "react";
import classes from "./Skills.module.css";
import Card from "../../UI/Card/Card";
import Bar from "../../UI/Bar/Bar";
import CircleBar from "../../UI/CircleBar/CircleBar";
import Words from "../../UI/Words/Words";
import { technicalSkills, entries } from "../../../dummmy";

const Skills: React.FC<any> = () => {
  return (
    <div className={classes.Skills}>
      <Card
        title="Skills"
        icon="fas fa-laptop-code"
        style={{ width: "100%", marginBottom: "1rem" }}
        classes={[classes.SkillCard]}
      >
        <p>
          I've started programming with the classics, Basic, Assembly when i was
          14. Then C , C++ for microcontrollers. Then came the Java. In my last
          5 years i've used JS (TypeScript), HTML, CSS building small and medium
          SEO friendly web apps, mobile apps, custom plugins, features,
          animations, and coding interactive layouts. I have also full-stack
          developer experience, with Node and Java. Im using mostly ReactJS or
          Angular2+ for the frontend and node for the backend. ReactNative or
          Ionic for mobile. I like to use cloud functions. I'm also do some 2d
          with HTML canvas and 3D with Three.js or Babylon.js for a better user
          experiences. Also working with microcontrollers like Arduino or
          Rapsberry, connecting to a web or mobile app.
        </p>
        <div className={classes.Wrapper}>
          <div className={classes.SkillsWrapper}>
              <h1>Technical skills</h1>
            <div className={classes.Technical}>
              <Bar bars={technicalSkills} />
            </div>
              <h1>Professional skills</h1>
            <div className={classes.Professional}>
              <div className={classes.ProfessionalRow}>
                <CircleBar title="Communication" precent={100} />
                <CircleBar title="Team work" precent={95} />
                <CircleBar title="Creativy" precent={98} />
              </div>
              <div className={classes.ProfessionalRow}>
                <CircleBar title="Leadership" precent={90} />
                <CircleBar title="Problem solving" precent={99} />
                <CircleBar title="Flexibility" precent={100} />
              </div>
            </div>
          </div>
          <div className={classes.Animation}>
            <Words entries={entries} />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Skills;
