import React from "react";
import classes from "./PortfolioViewer.module.css";
import Backdrop from "../../UI/Backdrop/Backdrop";
import Card from "../../UI/Card/Card";

const PortfolioViewer: React.FC<any> = ({ onClose, refrence }) => {
  return refrence ? (
    <React.Fragment>
      <Backdrop show={true} onClick={onClose} />
      <div className={classes.PortFolioViewer}>
        <i
          onClick={onClose}
          className="fas fa-times"
          style={{
            position: "absolute",
            right: "2%",
            top: "2%",
            cursor: "pointer",
          }}
        />
        <Card
          style={{ height: "100%", width: "100%" }}
          icon="fas fa-mobile-alt"
        >
          <div className={classes.Content}>
            <div className={classes.Description}>
              <div className={classes.Title}>{refrence.title}</div>
              <p>{refrence.description}</p>
              <div className={classes.Stack}>
                {refrence.technology.map((tech: any) => (
                  <div key={`${tech} ${refrence.title}`}>{tech}</div>
                ))}
              </div>
            </div>
            <div className={classes.ImageContainer}>
              {refrence.images.map((img: any) => (
                <div key={img}
                  className={classes.Image}
                  style={{ background: `url(${img})` }}
                ></div>
              ))}
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  ) : null;
};

export default PortfolioViewer;
