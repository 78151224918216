import React, { useState } from "react";
import classes from "./Portfolio.module.css";
import Card from "../../UI/Card/Card";
import { refrences } from "../../../dummmy";

const menuItems = [
  {
    key: "all",
    title: "All",
  },
  {
    key: "web",
    title: "Web",
  },
  {
    key: "mobile",
    title: "Mobile",
  },
  {
    key: "hardware",
    title: "Hardware",
  },
  {
    key: "designs",
    title: "Designs",
  },
  {
    key: "courses",
    title: "Courses",
  },
];

const Portfolio: React.FC<any> = ({ openRefrence }) => {
  const [active, setActive] = useState("all");
  const filteredRefrences = [];
  if (active !== "all") {
    filteredRefrences.push(
      ...refrences.filter((val: any) => val.key === active)
    );
  } else {
    filteredRefrences.push(...refrences);
  }
  const menuContent = (
    <div className={classes.Menu}>
      {menuItems.map((menu) => {
        return (
          <div
            key={menu.key}
            className={classes.MenuItem}
            onClick={() => setActive(menu.key)}
          >
            <span className={`${menu.key === active ? classes.Active : ""}`}>
              {menu.title}
            </span>
          </div>
        );
      })}
    </div>
  );
  const content: any = [[], [], [], []];
  filteredRefrences.forEach((ref: any, i) => {
    const num = i % (window.innerWidth > 768 ? 4 : 3);
    const heights = [15, 20, 30];
    content[num].push(
      <div
        onClick={() => {
          openRefrence(ref.id);
        }}
        key={ref.id}
        className={classes.Item}
        style={{
          background: `url(${ref.images[0]})`,
          height: `${heights[Math.floor(Math.random() * 3)]}vh`,
        }}
      ></div>
    );
  });

  return (
    <div className={classes.Portfolio}>
      <Card
        classes={[classes.PortfolioCard]}
        title="Portfolio"
        icon="fas fa-camera-retro"
        style={{ marginBottom: "1rem" }}
      >
        {menuContent}
        {filteredRefrences.length ? (
          <div className={classes.ImageContainer}>
            <div className={classes.ImageColumn}>
              {content[0].map((val: any) => val)}
            </div>
            <div className={classes.ImageColumn}>
              {content[1].map((val: any) => val)}
            </div>
            <div className={classes.ImageColumn}>
              {content[2].map((val: any) => val)}
            </div>
            {content[3].length ? (
              <div className={classes.ImageColumn}>
                {content[3].map((val: any) => val)}
              </div>
            ) : null}
          </div>
        ) : (
          <div className={classes.Coming}>Coming soon!</div>
        )}
      </Card>
    </div>
  );
};

export default Portfolio;
