import React from "react";
import classes from "./Card.module.css";

const Card: React.FC<any> = (props) => {
  return (
    <div
      className={`${props.classes?.join(" ")} ${classes.Card}`}
      style={{ ...props.style }}
    >
      {props.icon ? <i className={props.icon} /> : <></>}
      {props.title ? <h2>{props.title}</h2> : <></>}
      {props.children}
    </div>
  );
};

export default Card;
