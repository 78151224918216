import React from "react";
import classes from "./CircleBar.module.css";

type PropTypes = {
  title: string;
  precent: number;
};

const CircleBar: React.FC<PropTypes> = (props) => {
  return (
    <div className={classes.CircleBar}>
      <div className={classes.CircleWrapper}>
        <svg className={classes.Circle} viewBox="0 0 100 100">
          <path
            fill="none"
            strokeLinecap="round"
            strokeWidth="5"
            stroke="#8d8d8d"
            strokeDasharray="251.2,0"
            d="M50 10
            a 40 40 0 0 1 0 80
            a 40 40 0 0 1 0 -80"
          />
          <path
            fill="none"
            strokeLinecap="round"
            strokeWidth="5"
            stroke="#08fdd8"
            strokeDasharray={`${(251.2 / 100) * (props.precent - 1)},251.2`}
            d="M50 10
            a 40 40 0 0 1 0 80
            a 40 40 0 0 1 0 -80"
          />
          <text
            x="50"
            y="50"
            textAnchor="middle"
            dy="7"
            fontSize="20"
            fill="#fff"
          >
            {props.precent}%
          </text>
        </svg>
        <div>{props.title}</div>
      </div>
    </div>
  );
};

export default CircleBar;
