import React, { useState, useEffect } from "react";
import "./App.css";
import SideMenu from "./components/sidemenu/SideMenu";
import Profile from "./components/content/Profile/Profile";
import Content from "./components/content/Content";
import UIfx from "uifx";

import sd from "./assets/audio/sound.mp3";
import PortfolioViewer from "./components/content/Portfolio/PortfolioViewer";
import { refrences } from "./dummmy";
import * as firebase from "firebase";
const tick = new UIfx(sd, {
  volume: 0.4,
});

const App: React.FC<any> = () => {
  if (!firebase.apps.length) {
    firebase.initializeApp({
      apiKey: process.env.REACT_APP_apiKey,
      authDomain: process.env.REACT_APP_authDomain,
      databaseURL: process.env.REACT_APP_databaseURL,
      projectId: process.env.REACT_APP_projectId,
      storageBucket: process.env.REACT_APP_storageBucket,
      messagingSenderId: process.env.REACT_APP_messagingSenderId,
      appId: process.env.REACT_APP_appId,
      measurementId: process.env.REACT_APP_measurementId,
    });
  }
  firebase.analytics();

  const [translate, setTranslate] = useState("0%");
  const [active, setActive] = useState(0);
  const [selectedRef, setSelectedRef] = useState<any>(null);
  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    let listener = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };
    window.addEventListener("resize", listener);
    return () => {
      window.removeEventListener("resize", listener);
    };
  }, []);

  const handleMenuClick = (val: any) => {
    // tick.play();
    setTranslate("-100%");
    setTimeout(() => {
      document.getElementById("content")?.scrollTo(0, 0);
      setActive(val);
      setTranslate("0%");
    }, 900);
  };

  const handleRefrenceOpen = (id: any) => {
    const selected = refrences.filter((ref) => ref.id === id);
    setSelectedRef(selected[0]);
  };

  const isMobile = window.innerWidth < 768;
  return (
    <React.Fragment>
      <div className="background-logo">
        <img
          style={{
            width: "auto",
            height: "50%",
          }}
          // src="https://firebasestorage.googleapis.com/v0/b/portfolio-45e48.appspot.com/o/images%2Fcat_white.png?alt=media&token=49597607-8d41-462c-a9e7-ba7f2bed82ef"
          // src="https://firebasestorage.googleapis.com/v0/b/portfolio-45e48.appspot.com/o/images%2Fcat_gray.png?alt=media&token=df2b362a-07bb-442a-8d6f-36177255d664"
          src="https://firebasestorage.googleapis.com/v0/b/portfolio-45e48.appspot.com/o/images%2FUntitled_Artwork%208.png?alt=media&token=5d59299a-0466-4e5e-85c0-fad4bef163fc"
          alt="Logo"
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          width: "100vw",
          height: "100vh",
        }}
      >
        <SideMenu
          active={active}
          onMenuClick={handleMenuClick}
          isMobile={isMobile}
        />
        {!isMobile ? <Profile /> : null}
        <Content
          translate={translate}
          active={active}
          isMobile={isMobile}
          handleRefrenceOpen={handleRefrenceOpen}
        />
        {selectedRef ? (
          <PortfolioViewer
            refrence={selectedRef}
            onClose={() => setSelectedRef(null)}
          />
        ) : null}
      </div>
    </React.Fragment>
  );
};
export default App;
